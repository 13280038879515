* {
  box-sizing: border-box;
}

:root {
  /* --primary: #FFF9E9; */
  --primary: rgb(245, 240, 235);
  --secondary: rgb(128, 148, 151);
  /* --tertiary: #D49A3A; */
  --tertiary: rgb(173, 119, 98);
  --quatrenary: rgb(120, 112, 97);
  --cards: rgb(205, 196, 161, 0.5);
  --light: rgb(214, 144, 116);
  --black: rgb(46, 20, 3);
  --green: rgb(101, 125, 58);
  --logo: rgb(245, 240, 235);
  --photo-credit: rgb(231, 228, 225);
}

html,
body {
  min-height: 100%;
}

body {
  font-family: "Times New Roman", Times, serif;
  line-height: 1.5;
  font-size: 1.2rem;
  letter-spacing: 0.1em;
  background: var(--primary);
  color: var(--tertiary);
}

/* UTILS */

ul {
  list-style-type: none;
}

.list-text {
  line-height: 1.8;
  font-size: 1em;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

a {
  color: var(--black);
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  border-color: var(--light);
  cursor: pointer;
}

.href-links {
  color: var(--black);
  text-decoration: none;
}

.href-links:hover {
  color: var(--tertiary);
  text-decoration: none;
  cursor: pointer;
}

.photo-credit {
  font-size: 0.8rem;
  color: var(--cards);
  margin-top: -20px;
}

/* HEADER */

header {
  background-color: var(--primary);
  padding: 10px 0;
  border-bottom: 1px solid var(--black);
}

.nav-links {
  padding: 10px 20px 0 0;
  padding: 5px;
}

.nav-logo {
  margin: 0 0 0 20px;
}

.navActive {
  color: var(--tertiary);
  text-decoration: none;
}

.navActive {
  cursor: pointer;
}

.contact-btn {
  background-color: var(--tertiary);
  border-radius: 25px;
  margin: 0 auto;
  padding: 5px 10px 5px 10px;
  color: var(--black);
  text-decoration: none;
  text-align: center;
}

.contact-btn:hover {
  background-color: var(--light);
  color: var(--black);
  text-decoration: none;
  cursor: pointer;
}

.banner-section {
  background-color: var(--secondary);
  color: var(--black);
  border-bottom: 1px solid var(--black);
}

.banner-font {
  padding: 25px;
  margin: 0;
  font-size:larger;
  text-align: center;
}

/* HEADER ENDS */

/* MAIN SECTIONS */

section {
  padding: 100px 20px 50px 20px;
  border-bottom: 1px solid var(--black);
  flex-wrap: wrap;
}

.section-title {
  /* background-color: rgb(44, 44, 44, 0.6); */
  padding: 0 0 30px 0;
  margin: 20px 0 0 0;
  color: var(--black);
  /* margin-top: -70px; */
}

/* ABOUT START */

.about-section {
  background-color: var(--logo);
}

.portrait-CTA-container {
  display: flex;
  flex-direction: column;
}

.CTA-container {
  background-size: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.CTA {
  background-color: var(--tertiary);
  border-radius: 20px;
  padding: 5px 15px 5px 15px;
  color: var(--black);
  text-decoration: none;
  text-align: center;
}

.CTA:hover {
  background-color: var(--light);
  color: var(--black);
  text-decoration: none;
  cursor: pointer;
}

.portrait {
  max-width: 350px;
  /* width: 60%; */
  height: auto;
  border-radius: 0px 200px;
  margin-bottom: 30px;
}

.logo {
  max-width: 500px;
  /* width: 60%; */
  height: auto;
  margin-bottom: 30px;
}

.about-container {
  padding-left: 30px;
}

/* SOUND HEALING START */

.sound-healing {
  background-color: var(--secondary);
  color: var(--black);
}

.VST-uses-container {
  padding: 20px;
  border-bottom: 1px solid var(--black);
}

.VST-uses-list {
padding: 0 0 0 40px;
}
.sub-container {
  background-color: var(--cards);
  border-radius: 0px 70px;
  text-align: center;
  margin: 30px;
  padding: 0.5px 0 20px 0;
}

.singing-bowls-img {
  width: 100%;
  height: auto;
  border-radius: 70px;
}

.sound-note {
  padding: 20px 0 0 0;
}


/* COUNSELING */

.cards {
  background-color: var(--cards);
  margin: 30px 0 30px 30px;
  padding: 10px 10px 10px 20px;
  border-radius: 0 40px;
}

/* SERVICES */

.services-page {
  background-color: var(--logo);
}

.services-container {
  background-color: rgb(46, 20, 3, 0.1);
  padding: 30px;
  margin: 0 50px 0 0;
  border-radius: 30px;
}
.product-title {
  padding-left: 40px;
}
.pricing-title {
  padding-left: 30px;
}
.product-list{
  padding-bottom: 30px;
}
.pricing-list {
  padding-left: 80px;
}
.unavailable-list {
  padding-left: 30px;
}

/* .crystal-bowl-img-container {
  margin: 0 0 0 100px;
} */



/* INSURANCE */

.insurance-page {
  background-color: var(--tertiary);
}

.insurances {
  color: var(--black)
}

.insurance-text {
padding-left: 200px
}

/* CONTACT */
.form {
  padding: 0 20px 0 20px;
}

#selectSoundHealing,
#selectCounseling {
  background-color: var(--green);
}

/* FOOTER */

footer {
  background: var(--tertiary);
  color: var(--primary);
}

.footer-address {
  text-align: center;
  margin-bottom: 10px;
}

.created-by {
  font-size: small;
  text-align: center;
}

.logo-footer {
  max-width: 250px;
  /* width: 60%; */
  height: auto;
  margin: 10px;
}

/* Large LAPTOP SCREEN  */
@media only screen 
and (min-width : 1824px) {

  header {
    position: sticky;
    top: 0;
    z-index: 9999;
  }

   .about-section {
    padding: 100px 20px 200px 20px;
  border-bottom: 1px solid var(--black);
  }

  .nav-container {
    margin: 0 10px 0 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: right;
  
  }
}


/* small laptop SCREEN  */
@media only screen 
and (min-width : 1224px) and (max-width: 1823px) {
  header {
    position: sticky;
    top: 0;
    z-index: 9999;
  }

  .photo-credit {
    padding: 0;
  }

  .about-section {
    padding: 100px 20px 200px 20px;
  border-bottom: 1px solid var(--black);
  }

  .nav-container {
    margin: 0 0 0 -32px;
      display: flex;
      flex-wrap: wrap;
      justify-content: right;
  
  }
}


/* TABLET SCREEN  */
@media only screen 
and (min-width : 768px) 
and (max-width : 1024px){
  
header {
  background-color: var(--primary);
  padding: 10px 0;
  border-bottom: 1px solid var(--black);
  position: sticky;
   top: 0;
  z-index: 9999;
}

.sub-container {
  padding: 0.5px 20px 20px 20px;
}
.services-container {
  margin: 0;
}
.pricing-title {
  padding-left: 50px;
}

.crystal-bowl-img-container {
  padding: 30px 10px 0 0px; 
}

.photo-credit {
  font-size: 0.8rem;
  color: var(--cards);
  margin-top: -20px;
  padding-left: 10px;
}

.cards {
  margin: 20px 10px;
}

.insurance-text {
  padding-bottom: 40px;
}
}

/* MOBILE SCREEN */
@media only screen and (min-width: 320px)  and (max-width : 767px) {
 
header {
  background-color: var(--primary);
  padding: 0 0 10px 0;
  border-bottom: 1px solid var(--black);
  text-align: center;
}

.nav-container {
  margin: 0 0 0 -32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}
  
  .nav-links {
    padding: 5px 7px 5px 7px;
  }
  
  .nav-logo {
    margin: 0;
  }
  
  body {
    line-height: 1.5;
    font-size: 1rem;
  }
  section {
    padding: 30px 10px 50px 10px;
  }

  .section-title {
    text-align: center;
  }

  .photo-credit {
    padding: 60px;
    font-size: x-small;
  }

  .about-container {
    padding-left: 12px;
  }

  .VST-uses-container {
    padding: 0;
  }
  .sub-container {
    background-color: var(--cards);
    border-radius: 0px 70px;
    text-align: center;
    margin: 20px 0 20px 0;
    padding: 0.5px 20px 20px 20px;
  }

  .VST-btn {
    margin-top: 20px;
  }

  .singing-bowls-img {
    max-width: 70%;
  }
  
  .cards {
   max-width: 90%;
  }

  .pricing-title {
    padding-left: 55px;
  }
  .services-container {
    margin: 0;
  }
  .crystal-bowl-img-container {
    padding: 30px; 
  }

  .insurance-text {
    padding: 0;
  }

  .list-text {
    text-align: center;
    padding: 0;
    margin: 0;
  }

  .holding-bowl {
padding: 30px 0 0 0;
  }
  
}